.ProgressBar {
  width: 100%;
  height: 400%;
  background-color: teal;
  border-radius: 5px;
}

.Progress {
  background-color: teal;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
